import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can set an expiration date for video content. A video gets deleted when expires. These videos can be saved by setting the time of the expiration.`}</p>
    <h2>{`Get video expiration`}</h2>
    <p>{`Returns information on when the video will expire.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/videos/{video_id}/expiration.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value pairs under an `}<inlineCode parentName="p">{`expiration`}</inlineCode>{` key.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rule`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Possible values: never, at_given_time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expires_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If value of `}<inlineCode parentName="td">{`rule`}</inlineCode>{` field is `}<inlineCode parentName="td">{`at_given_time`}</inlineCode>{`, this field contains the Unix timestamp of the expiration. (If the value is `}<inlineCode parentName="td">{`never`}</inlineCode>{`, this field is not provided.)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`forbidden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Managing the expiration is disabled at system level or user has no permission to manage the expiration of videos`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Set video expiration`}</h2>
    <p>{`If you don’t want certain video assets to be deleted you can set the video to never expire.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/videos/{video_id}/expiration.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters for the PUT request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rule`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported value: `}<inlineCode parentName="td">{`never`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bad_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rule is not provided value is not valid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`forbidden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Managing the expiration is disabled at system level or user has no permission to manage the expiration of videos`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      